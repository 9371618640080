import {
  USER_FETCHED,
  USER_FETCH_ERROR,
  USER_LOGIN_KEY,
} from "../actions/action_login";

const profile = (state = {}, action) => {
  switch (action.type) {
    case USER_FETCHED:
      return { ...state, userFetched: action.payload };
    case USER_FETCH_ERROR:
      return { ...state, userFetchError: action.payload };
    case USER_LOGIN_KEY:
      return { ...state, userLoginKey: action.payload };
    default:
      return state;
  }
};

export default profile;
