import {
  ACTIVATE_STAFF,
  ACTIVATE_STAFF_SUCCESS,
  ACTIVATE_STAFF_ERROR,
} from "../actions/action_staff_action";

const initialState = {
  activatingStaff: false,
  activatedStaff: null,
  activationError: null,
};

function staffAction(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_STAFF:
      return {
        ...state,
        activatingStaff: true,
        activationError: null,
        activatedStaff: true,
      };
    case ACTIVATE_STAFF_SUCCESS:
      return {
        ...state,
        activatedStaff: action.payload,
        activatingStaff: false,
        activationError: null,
      };
    case ACTIVATE_STAFF_ERROR:
      return {
        ...state,
        activationError: action.payload,
        activatingStaff: false,
      };

    default:
      return state;
  }
}

export default staffAction;
