import axios from "axios";
import {
  BASE_URL,
  SEARCH_USER,
  SEARCH_USER_ERROR,
  OPEN_MODAL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./types";

import { errorSwitch, appInterceptor } from "./utils";

export const STAFFS_FETCHED = "STAFFS_FETCHED";
export const STAFFS_FETCH_ERROR = "STAFFS_FETCH_ERROR";
export const ALL_STAFFS_FETCHED = "ALL_STAFFS_FETCHED";
export const ALL_STAFFS_FETCH_ERROR = "ALL_STAFFS_FETCH_ERROR";
export const STAFF_FETCHED = "STAFF_FETCHED";
export const STAFF_FETCH_ERROR = "STAFF_FETCH_ERROR";
export const STAFF_DELETED = "STAFF_DELETED";
export const STAFF_DELETE_ERROR = "STAFF_DELETE_ERROR";
export const STAFF_CREATED = "STAFF_CREATED";
export const STAFF_CREATE_ERROR = "STAFF_CREATE_ERROR";
export const STAFF_UPDATED = "STAFF_UPDATED";
export const STAFF_UPDATE_ERROR = "STAFF_UPDATE_ERROR";
export const APP_USERS_FETCHED = "APP_USERS_FETCHED";
export const APP_USERS_FETCH_ERROR = "APP_USERS_FETCH_ERROR";
export const APP_USER_FETCHED = "APP_USER_FETCHED";
export const APP_USER_FETCH_ERROR = "APP_USER_FETCH_ERROR";
export const APP_USER_DELETED = "APP_USER_DELETED";
export const APP_USER_DELETE_ERROR = "APP_USER_DELETE_ERROR";
export const USER_ADDED = "USER_ADDED";
export const USER_ADD_ERROR = "USER_ADD_ERROR";
export const FORGOT_PASSWORD_USER = "FORGOT_PASSWORD_USER";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const IS_FORGOT_PASSWORD_LOADING = "IS_FORGOT_PASSWORD_LOADING";
export const STAFF_RESET_PASSWORD = "STAFF_RESET_PASSWORD";
export const STAFF_RESET_PASSWORD_ERROR = "STAFF_RESET_PASSWORD_ERROR";
export const IS_RESET_PASSWORD_LOADING = "IS_RESET_PASSWORD_LOADING";

export const STAFF_TOGGLE_STATUS = "STAFF_TOGGLE_STATUS";
// export const STAFF_TOGGLE_STATUS_ERROR = "STAFF_TOGGLE_STATUS_ERROR";

let apiUrl = BASE_URL + "staffs/";
let newApiUrl = BASE_URL + "staffs/staffAndSearch";

appInterceptor();

export function fetchStaffs(type) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: newApiUrl + type,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFFS_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: STAFFS_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

export function fetchStaff(type) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: apiUrl + type,
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFF_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: STAFF_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

export const resetUserPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetUserPasswordFailure = (error) => ({
  type: RESET_PASSWORD_ERROR,
  payload: error,
});

export function resetUserPassword(adminUsername, username, newPassword) {
  // console.log(adminUsername, username, newPassword);
  return (dispatch) => {
    const requestData = {
      adminUsername: adminUsername,
      username: username,
      newPassword: newPassword,
    };

    axios({
      method: "POST",
      url: apiUrl + "reset-password",
      data: requestData,
    })
      .then((response) => {
        dispatch(resetUserPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(resetUserPasswordFailure(error.message));
      });
  };
}

export function deleteStaff(type) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: apiUrl + type,
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFF_DELETED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(error.response.data.message);
          dispatch({
            type: STAFF_DELETE_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't delete staff");
          console.log(errorSwitch(error));
          console.log(error.response.data.message);
        });
    });
  };
}



export const toggleStaffStatus = (userId, status) => {
  return async (dispatch) => {
    try {
      const url = `${apiUrl}${userId}/activate-deactivate?status=${status}`;
      const response = await axios.put(url);

      return response;
    } catch (error) {
      return(error.message || "An error occurred while toggling staff status")
    }
  };
};

export function createStaff(staffInfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, staffInfo)
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFF_CREATED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: STAFF_CREATE_ERROR,
            payload: errorSwitch(error),
          });
          console.log(errorSwitch(error));
        });
    });
  };
}
export function resetStaffPassword(email, newPassword) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}staffs/reset-password-by-user?email=${email}`,
          newPassword
        )
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFF_CREATED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: STAFF_CREATE_ERROR,
            payload: errorSwitch(error),
          });
          console.log(errorSwitch(error));
        });
    });
  };
}

export function updateStaff(staffInfo) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl, staffInfo)
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: STAFF_UPDATED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: STAFF_UPDATE_ERROR,
            payload: errorSwitch(error),
          });
          console.log(errorSwitch(error));
        });
    });
  };
}

export function fetchAllStaffs(type) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: apiUrl + type,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: ALL_STAFFS_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: ALL_STAFFS_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

// Fetch users by app
let userApi = BASE_URL + "userapps/";
export function fetchAppUsers(type) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        // url: `http://localhost:9100/auth-service/userapps/?size=1`,
        url: userApi + type,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: APP_USERS_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: APP_USERS_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

export function fetchAppUsersPaginated(pageNumber, type) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${userApi + type}&page=${pageNumber}`,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: APP_USERS_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: APP_USERS_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

export function searchAppUser(username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${BASE_URL}staffs/import/${username}`,
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: SEARCH_USER,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          console.log("found no user", errorSwitch(error));
          reject(errorSwitch(error));
          dispatch({
            type: SEARCH_USER_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}
export function forgotPasswordUser(email) {
  return (dispatch) => {
    dispatch({
      type: IS_FORGOT_PASSWORD_LOADING,
      payload: true,
    });
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${BASE_URL}staffs/forgot-password?email=${email}`,
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          console.log("====RESULT===", responseJSON);
          dispatch({
            type: FORGOT_PASSWORD_USER,
            payload: responseJSON,
          });
          dispatch({
            type: IS_FORGOT_PASSWORD_LOADING,
            payload: false,
          });
        })
        .catch((error) => {
          console.log("Error on forgotten password", errorSwitch(error));
          reject(errorSwitch(error));
          console.log("====ERROR===", error);
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            payload: errorSwitch(error),
          });
          dispatch({
            type: IS_FORGOT_PASSWORD_LOADING,
            payload: false,
          });
          console.log("Rejected.. Couldn't not restore forgotten password");
          console.log(errorSwitch(error));
        });
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch({
      type: OPEN_MODAL,
      payload: false,
    });
  };
}

// Add users to app
export function addUserToApp(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(userApi, user, {
          // headers: staffHeaders
        })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: USER_ADDED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: USER_ADD_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

// Fetch a user from app
export function fetchUserApp(user) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: userApi + user,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: APP_USER_FETCHED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: APP_USER_FETCH_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

// Remove users from app
export function removeFromApp(user) {
  // updateHeaders();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: userApi + user,
        // headers: staffHeaders
      })
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch({
            type: APP_USER_DELETED,
            payload: responseJSON,
          });
        })
        .catch((error) => {
          reject(errorSwitch(error));
          dispatch({
            type: APP_USER_DELETE_ERROR,
            payload: errorSwitch(error),
          });
          console.log("Rejected.. Couldn't fetch staffs");
          console.log(errorSwitch(error));
        });
    });
  };
}

export const downloadStaffList = () => {
  axios({
    method: "GET",
    url: `${BASE_URL}staffs/download?csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "stafflist.csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};
