import {
  STAFFS_FETCHED,
  STAFFS_FETCH_ERROR,
  ALL_STAFFS_FETCHED,
  ALL_STAFFS_FETCH_ERROR,
  STAFF_FETCHED,
  STAFF_FETCH_ERROR,
  STAFF_DELETED,
  STAFF_DELETE_ERROR,
  STAFF_CREATED,
  STAFF_CREATE_ERROR,
  STAFF_UPDATED,
  STAFF_UPDATE_ERROR,
  APP_USERS_FETCHED,
  APP_USERS_FETCH_ERROR,
  APP_USER_FETCHED,
  APP_USER_FETCH_ERROR,
  APP_USER_DELETED,
  APP_USER_DELETE_ERROR,
  USER_ADDED,
  USER_ADD_ERROR,
} from "../actions/action_staff";
import {
  SEARCH_USER,
  SEARCH_USER_ERROR,
  OPEN_MODAL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  STAFF_TOGGLE_STATUS_ERROR,
  STAFF_TOGGLE_STATUS_REQUEST,
  STAFF_TOGGLE_STATUS_SUCCESS,
} from "../actions/types";

const initialState = {
  staffsFetched: [],
  staffsFetchError: null,
  allStaffsFetched: [],
  allStaffsFetchError: null,
  staffFetched: null,
  staffFetchError: null,
  staffDeleted: null,
  staffDeleteError: null,
  staffCreated: null,
  staffCreateError: null,
  staffUpdated: null,
  staffUpdateError: null,
  appUsersFetched: [],
  appUsersFetchError: null,
  appUserFetched: null,
  appUserFetchError: null,
  appUsersDeleted: null,
  appUsersDeleteError: null,
  userAdded: null,
  userAddError: null,
  LDAP_User: null,
  LDAP_User_Error: null,
  importUser: false,
  loading: false,
  error: null,
  resetPasswordSuccess: null,
  resetPasswordError: null,
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        importUser: false,
        LDAP_User_Error: false,
        LDAP_User: false,
      };
    case SEARCH_USER:
      return {
        ...state,
        LDAP_User: action.payload,
        LDAP_User_Error: false,
        importUser: true,
      };

    case SEARCH_USER_ERROR:
      return {
        ...state,
        LDAP_User: false,
        LDAP_User_Error: action.payload,
        importUser: true,
      };
    case STAFFS_FETCHED:
      return { ...state, staffsFetched: action.payload };
    case STAFFS_FETCH_ERROR:
      return { ...state, staffsFetchError: action.payload };
    case ALL_STAFFS_FETCHED:
      return { ...state, allStaffsFetched: action.payload };
    case ALL_STAFFS_FETCH_ERROR:
      return { ...state, allStaffsFetchError: action.payload };
    case STAFF_FETCHED:
      return { ...state, staffFetched: action.payload };
    case STAFF_FETCH_ERROR:
      return { ...state, staffFetchError: action.payload };
    case STAFF_DELETED:
      return { ...state, staffDeleted: action.payload };
    case STAFF_DELETE_ERROR:
      return { ...state, staffDeleteError: action.payload };
    case STAFF_CREATED:
      return { ...state, staffCreated: action.payload };
    case STAFF_CREATE_ERROR:
      return { ...state, staffCreateError: action.payload };
    case STAFF_UPDATED:
      return { ...state, staffUpdated: action.payload };
    case STAFF_UPDATE_ERROR:
      return { ...state, staffUpdateError: action.payload };
    case APP_USERS_FETCHED:
      return { ...state, appUsersFetched: action.payload };
    case APP_USERS_FETCH_ERROR:
      return { ...state, appUsersFetchError: action.payload };
    case APP_USER_FETCHED:
      return { ...state, appUserFetched: action.payload };
    case APP_USER_FETCH_ERROR:
      return { ...state, appUserFetchError: action.payload };
    case APP_USER_DELETED:
      return { ...state, appUsersDeleted: action.payload };
    case APP_USER_DELETE_ERROR:
      return { ...state, appUsersDeleteError: action.payload };
    case USER_ADDED:
      return { ...state, userAdded: action.payload };
    case USER_ADD_ERROR:
      return { ...state, userAddError: action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: action.payload };
    case RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordError: action.payload };
    default:
      return state;
  }
};

export default staff;
