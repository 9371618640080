import { BASE_URL } from "./types";
import { itExists } from "../Utils/utitlityFunctions";
import CryptoJS from "crypto-js";
import axios from "axios";

export const USER_FETCHED = "USER_FETCHED";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";
export const USER_LOGIN_KEY = "USER_LOGIN_KEY";
export const IS_LOADING = "IS_LOADING";

export function fetchUser(type, appUser, module, userKey) {
  if (!itExists(module)) new Error("module cannot be empty");
  const userData = {
    username: CryptoJS.AES.encrypt(appUser.username, userKey).toString(),
    password: CryptoJS.AES.encrypt(appUser.password, userKey).toString(),
  };
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + type, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
          module: module,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((responseJSON) => {
          resolve(responseJSON);
          dispatch(loadUser(responseJSON));
          dispatch({
            type: IS_LOADING,
            payload: false,
          });
        })
        .catch((error) => {
          reject(error);
          dispatch({
            type: USER_FETCH_ERROR,
            payload: "Login failure. Incorrect username or password",
          });
          dispatch({
            type: IS_LOADING,
            payload: false,
          });
        });
    });
  };
}

export function loadUser(results) {
  return {
    type: USER_FETCHED,
    payload: results,
  };
}

export function getLoginKey() {
  return (dispatch) => {
    return new Promise(() => {
      axios
        .post(`${BASE_URL}auth/yek_cne`)
        .then(({ data }) => {
          dispatch({
            type: USER_LOGIN_KEY,
            payload: data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}
