const { REACT_APP_BASE_URL } = process.env;
export const BASE_URL = `${REACT_APP_BASE_URL}/auth-service/`;
export const FETCH_AUDIT_LOGS_LOADER = "fetch_audit_logs_loader";
export const FETCH_AUDIT_LOGS = "fetch_audit_logs";
export const FETCH_AUDIT_LOGS_ERROR = "fetch_audit_logs_error";
export const SEARCH_USER = "search_user";
export const SEARCH_USER_ERROR = "search_user_error";
export const TEST_PAGE = "test_page";
export const TEST_PAGE_ERROR = "test_page_error";
export const OPEN_MODAL = "open_modal";
export const CLOSE_MODAL = "close_modal";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export let userToken = "";

if (sessionStorage.getItem("userData")) {
  userToken = JSON.parse(sessionStorage.getItem("userData"))
    ? JSON.parse(sessionStorage.getItem("userData")).token
    : "";
}
