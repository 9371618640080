import { LOCALE_SET } from '../actions/action_locale';
import { IS_LOADING } from '../actions/action_login';

const locale = (state = { lang: 'en' }, action) => {
  switch (action.type) {
    case LOCALE_SET:
      return { ...state, lang: action.payload };
      case IS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

export default locale;