import axios from "axios";
import { BASE_URL } from "./types";

let apiUrl = BASE_URL + "staffs/";

export const ACTIVATE_STAFF = "ACTIVATE_STAFF";
export const ACTIVATE_STAFF_SUCCESS = "ACTIVATE_STAFF_SUCCESS";
export const ACTIVATE_STAFF_ERROR = "ACTIVATE_STAFF_ERROR";

export function activateStaff(staffId) {
  return async (dispatch) => {
    dispatch({ type: ACTIVATE_STAFF });
    try {
      const response = await axios.post(apiUrl + staffId);
      dispatch({
        type: ACTIVATE_STAFF_SUCCESS,
        payload: response.data, // Assuming response.data contains the activated staff data
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: ACTIVATE_STAFF_ERROR,
        payload: error,
      });
      throw error;
    }
  };
}
